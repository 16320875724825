import styled from '@emotion/styled'

import { TransitionStatus } from 'react-transition-group'

import { setTransition } from 'utils/style'

import theme from 'settings/theme'

export const Message = styled.div<{state?: TransitionStatus}>`
  margin: 3px 0 20px 0;
  font-size: 12px;
  color: ${theme.colors.error};
  background-color: ${theme.colors.error + 0x10};
  text-align: left;
  padding: 0 8px;
  border-radius: 5px;
  border-left: 4px solid ${theme.colors.error};
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px;
  overflow: hidden;
  transition: all 200ms;
  height: ${({ state }) => setTransition<number | string>(state, {
    active: '100%',
    disabled: 0
})};
  p, svg {
    transition: opacity 200ms 100ms;
    opacity: ${({ state }) => setTransition(state, {
        active: 1,
        disabled: 0
    })};
  }
  
  p {
    padding: 8px 0;
  }
`
