import { GetServerSidePropsContext } from 'next/types'

import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import * as https from 'https'

import { getTokenUniversal, STORAGE_JWT_TOKEN_KEY } from 'utils/auth'
import * as endpoints from 'utils/constants/endpoints'
import storage from 'utils/storage'

import { getErrorMessages } from './errors'
import { getLocale, isServer } from './helpers'

/**
 * ExtendedAxiosRequestConfig extends the AxiosRequestConfig interface to include additional properties
 * for handling authentication tokens, request context, and global error handling.
 * @property {string} [token] - Optional authentication token to be included in the request.
 * @property {object} [context] - Optional context object that may contain request-specific information.
 * @property {object} [context.req] - Optional request object.
 * @property {Partial<IncomingHttpHeaders>} [context.req.headers] - Optional partial headers for the request.
 * @property {boolean} [globalError] - Optional flag to indicate if global error handling should be applied.
 */
interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    token?: string;
    context?: GetServerSidePropsContext;
    globalError?: boolean;
}

/**
 * Creates an Axios instance with custom configuration and interceptors.
 *
 * @param {ExtendedAxiosRequestConfig} options - Configuration options for the Axios instance.
 * @returns {AxiosInstance} - Configured Axios instance.
 *
 * The function determines if the code is running on the server or client side and sets the appropriate base URL.
 * It also configures the Axios instance with custom headers, including the 'Accept-Language' header and an authorization token if available.
 *
 * Request Interceptor:
 * - Adds an authorization token to the request headers if available.
 *
 * Response Interceptor:
 * - Stores a new token from the response data if available.
 * - Handles errors by displaying notifications and removing the token if the response status is 401 (Unauthorized).
 */
const api = (options?: ExtendedAxiosRequestConfig) => {
    let host = ''

    if (options?.context) {
        host = options.context.req.headers.host
    } else if (!isServer()) {
        const { hostname, port } = window.location
        host = port ? `${hostname}:${port}` : hostname
    }
    const isDevelopment = host === '127.0.0.1:3000' || host === 'localhost:3000'

    const protocol = isDevelopment ? 'http://' : 'https://'
    const baseURL = isDevelopment
        ? 'http://127.0.0.1:8000'
        : `${protocol}${host}/api`


    const axiosInstance = axios.create({
        ...options,
        baseURL,
        headers: {
            'Accept-Language': getLocale(options?.context),
            ...options?.headers
        },
        httpsAgent: new https.Agent({ rejectUnauthorized: false })
    })

    axiosInstance.interceptors.request.use(
        (config:ExtendedAxiosRequestConfig) => {
            const token = getTokenUniversal(config.context)
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        },
        (error) => {
            console.error('Request Error:', error)
            return Promise.reject(error)
        }
    )

    axiosInstance.interceptors.response.use(
        (response) => {
            if (response.data && response.data.token) {
                storage.set(STORAGE_JWT_TOKEN_KEY, response.data.token, 'cookies')
            }
            return response
        },
        async (error) => {
            if (!isServer) {
                const notification = (await import('utils/hook/useNotification')).default()
                const messages = getErrorMessages(error)
                if (error.response?.status === 401) {
                    storage.remove(STORAGE_JWT_TOKEN_KEY, 'cookies')
                    notification.error('Session expirée. Veuillez vous reconnecter.')
                } else {
                    messages.forEach((message) => notification.error(message))
                }
            }
            return Promise.reject(error)
        }
    )

    return axiosInstance
}
/**
 * Makes a CRUD request with the method applied to the specified endpoint with optional configuration options.
 * @param endpoint the endpoint to which the request is made
 * @param options  configuration options for the request like headers, token, etc.
 * @returns a promise that resolves to the response from the request
 */
const get = (endpoint: string, options?: ExtendedAxiosRequestConfig): Promise<AxiosResponse> => {
    // console.log('API GET request:', endpoint, options)
    return api(options).get(endpoint)
}

const post = (endpoint: string, data?: unknown, options?: ExtendedAxiosRequestConfig): Promise<AxiosResponse> => {
    return api(options).post(endpoint, data)
}

const put = (endpoint: string, data: unknown, options?: ExtendedAxiosRequestConfig): Promise<AxiosResponse> =>
    api(options).put(endpoint, data)

const patch = (endpoint: string, data?: unknown, options?: ExtendedAxiosRequestConfig): Promise<AxiosResponse> =>
    api({
        ...options,
        headers: { ...options?.headers, 'Content-Type': 'application/merge-patch+json' }
    }).patch(endpoint, data)

const remove = (endpoint: string, options?: ExtendedAxiosRequestConfig): Promise<AxiosResponse> =>
    api(options).delete(endpoint)

const getItems = <T = any>(data: AxiosResponse['data']): T[] => data?.['hydra:member'] || []

const getTotalItems = (data: AxiosResponse['data']): number => data?.['hydra:totalItems'] || 0

export default {
    get,
    post,
    put,
    patch,
    delete: remove,
    getItems,
    getTotalItems,
    endpoints
}
