
import api from 'utils/api'
import { getBaseUrl } from 'utils/dom'
import { Reject } from 'utils/types/promise'

import routes from 'settings/routes.json'

const resetPasswordRequest = (
    email: string
): Promise<void | Reject> => new Promise((resolve, reject) => {
    api
        .post(api.endpoints.API_FORGOT_PASSWORD_RESET_PASSWORD_REQUEST, {
            email,
            resetPasswordUrl: getBaseUrl() + routes.forgotPassword.updatePassword
        })
        .then(() => resolve())
        .catch(({ response }) => {
            reject(response.data)
        })
})

export default resetPasswordRequest
