import dynamic from 'next/dynamic'

import React, { useState } from 'react'

import { Form, Input, Upload, UploadProps } from 'antd'
import { useTranslation } from 'react-i18next'

import upload from 'services/uploads'

import { Label } from './styles'

const ImgCrop = dynamic(() => import('antd-img-crop'), { ssr: false })

interface ImageUploadProps {
    name?: string
    label?: string
    endpoint? : string
    defaultValues?
    ratio?: number
}
const ImageUpload = ({
    name,
    label,
    endpoint,
    defaultValues,
    ratio = 16 / 9
}: ImageUploadProps): JSX.Element => {
    const [fileList, setFileList] = useState(defaultValues || [])

    const { t } = useTranslation()
    const form = Form.useFormInstance()

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
    }

    const onPreview = async (file) => {
        let src = file.url as string

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result as string)
            })
        }

        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const uploadImage:UploadProps['customRequest'] = ({ file, onSuccess, onError }) => {
        const data = new FormData()
        data.append('file', file)

        upload(endpoint, data)
            .then(response => {
                form.setFieldsValue({ [name]: response?.['@id'] })
                onSuccess(response)
            })
            .catch(error => onError(error))
    }

    return (
        <div>
            {label && <Label>{label}</Label>}
            <ImgCrop
                aspect={ratio}
                rotate
                modalTitle={t('form.input.image.modal.title')}
            >
                <Upload
                    maxCount={1} // @todo multiple case not considered
                    customRequest={uploadImage}
                    accept="image/*"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    onRemove={() => form.setFieldsValue({ [name]: null })}
                >
                    {fileList.length < 5 && '+ Upload'}
                </Upload>
            </ImgCrop>
            <Form.Item name={name} hidden>
                <Input />
            </Form.Item>
        </div>

    )
}

export default ImageUpload
