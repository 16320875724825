import styled from '@emotion/styled'

import { setSize } from 'utils/style'

import { global, header } from 'settings/theme'

export const Wrapper = styled.div`
  padding: 80px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - ${header.height}px);
  h2, button, form {
    margin: 0 auto;
    text-align: center;
  }
  
  h2 {
    padding-top: 20px;
    margin: 30px 0;
  }
  
  form {
    max-width: 394px;
  }
  
  button {
    box-shadow: 0 3px 6px #2C17043F;
  }
`

export const Content = styled.div`
  background-color: white;
  box-shadow: ${global.shadow};
  border-radius: ${setSize(global.br)};
  padding: 40px;
  width: 100%;
  max-width: 670px;
  position: relative;
`

export const WrapperIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 8px;
  transform: translate(-50%, -50%);
`

export const FormOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 56px;
`
